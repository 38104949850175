export function initSubNavToggleMobile(Offcanvas) {
  const subNavToggleElements = document.querySelectorAll('.navbar-mobile button.nav-link');

  subNavToggleElements.forEach(subNavToggleElement => {

    const subNavToggleTargetString = subNavToggleElement.getAttribute('data-bs-target'),
      subNavToggleTarget = subNavToggleTargetString ? document.querySelector(subNavToggleTargetString) : false,
      mobileMainNav = document.querySelector('.navbar-mobile-main') || false;
    if (!subNavToggleTarget) return;

    const backButton = subNavToggleTarget.querySelector('.back-item .nav-link');

    subNavToggleElement.addEventListener('click', () => Offcanvas.getOrCreateInstance(subNavToggleTarget).show());

    backButton.addEventListener('click', () => Offcanvas.getOrCreateInstance(subNavToggleTarget).hide());

    subNavToggleTarget.addEventListener('hide.bs.offcanvas', () => mobileMainNav.classList.add('hiding-subnav'));

    subNavToggleTarget.addEventListener('show.bs.offcanvas', () => mobileMainNav.classList.add('showing-subnav'));

    subNavToggleTarget.addEventListener('shown.bs.offcanvas', () => {
      mobileMainNav.classList.remove('showing-subnav');
      mobileMainNav.classList.add('show-subnav');
    });

    subNavToggleTarget.addEventListener('hidden.bs.offcanvas', () => {
      mobileMainNav.classList.remove('hiding-subnav');
      mobileMainNav.classList.remove('show-subnav');
    });
  })
}
