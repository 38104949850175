import Headspace from "headspace";

export function initHeadroom() {
  const headerElement = document.querySelector('header');

  Headspace(headerElement, {
    startOffset: 139,
    tolerance: 8,
    showAtBottom: false,
    classNames: {
      base: 'headspace',
      fixed: 'is--fixed',
      hidden: 'is--hidden'
    }
  })
}
