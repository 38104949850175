export function elementIsMegaMenuNav(element) {
  return element ? element.closest('.navbar-mega-nav') : false;
}

function elementIsMegaMenuToggle(element) {
  return element ? element.classList.contains('nav-link') && element.hasAttribute('data-target') : false;
}

export function mouseLeaveMegaMenu(event) {
  return !elementIsMegaMenuToggle(event.relatedTarget) && !elementIsMegaMenuNav(event.relatedTarget);
}

export function deactivateMegaMenu(headerElement, bodyElement, megaMenuElement, mainNavItems, megaMenuNavigationElements) {
  headerElement.classList.remove('show');
  bodyElement.style.removeProperty('overflow');
  megaMenuElement.classList.remove('show');
  mainNavItems.forEach(mainNavItem => mainNavItem.classList.remove('show'));
  megaMenuNavigationElements.forEach(megaMenuNavigationElement => megaMenuNavigationElement.classList.remove('show'));
  megaMenuElement.style.removeProperty('--container-height');
}

export function activateMegaMenu(headerElement, bodyElement, megaMenuElement) {
  headerElement.classList.add('show');
  bodyElement.style.setProperty('overflow', 'hidden');
  megaMenuElement.classList.add('show');
}
