export function initPopover(Popover) {
  document.querySelectorAll('[data-bs-toggle="popover"]').forEach(element => {
    return new Popover(element, {
      container: element,
      trigger: 'hover',
      placement: 'top',
      html: true
    })
  });
}
