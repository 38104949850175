function getCardTitleElement(card) {
  return card.querySelector('.card-title');
}

function getCardsOnSameHeight(referenceCard, cards) {
  const sameHeightArray = [];
  const cardYCoordinate = referenceCard.getBoundingClientRect().y;

  cards.forEach(card => {
    const cardIterationCoordinateY = card.getBoundingClientRect().y
    if (cardIterationCoordinateY === cardYCoordinate) sameHeightArray.push(card);
  });

  return sameHeightArray;
}

function getCardInnerTitleHeight(card) {
  return card.querySelector('.card-title .inner').getBoundingClientRect().height;
}

function getMaxHeightOfCardsInnerTitle(cardsOnSameY) {
  const heightArray = [];
  cardsOnSameY.map(cardOnSameY => heightArray.push(getCardInnerTitleHeight(cardOnSameY)));
  return Math.max(...heightArray);
}

function setMinHeightOnCardTitle(card, maxHeight) {
  getCardTitleElement(card).setAttribute('style', `min-height: ${maxHeight}px`);
}

export function initCardTitleHeight() {
  const allCards = document.querySelectorAll('main .description-teaser-card');
  allCards.forEach(card => {
    const cardsOnSameHeight = getCardsOnSameHeight(card, allCards),
      maxHeight = getMaxHeightOfCardsInnerTitle(cardsOnSameHeight);

    cardsOnSameHeight.map(cardOnSameHeight => setMinHeightOnCardTitle(cardOnSameHeight, maxHeight));
  })
}
