export function initNavbarToggle(Offcanvas) {

  const headerElement = document.querySelector('header'),
    navbarToggleElement = document.querySelector('.navbar-toggle'),
    mobileNavbar = document.querySelector('.navbar-mobile'),
    mobileSubNavs = document.querySelectorAll('.navbar-mobile-subs .navbar-nav'),
    navMaxWidthQuery = "(min-width: 1200px)";

  navbarToggleElement.addEventListener('click', () => {
    Offcanvas.getOrCreateInstance(mobileNavbar).toggle();
  })

  mobileNavbar.addEventListener('hide.bs.offcanvas', e => {
    if (e.target === mobileNavbar) {
      headerElement.classList.add('hiding');
      mobileSubNavs.forEach(mobileSubNav => Offcanvas.getOrCreateInstance(mobileSubNav).hide());
    }
  })

  mobileNavbar.addEventListener('hidden.bs.offcanvas', e => {
    if (e.target === mobileNavbar) {
      headerElement.classList.remove('hiding');
      headerElement.classList.remove('show');
    }
  })

  mobileNavbar.addEventListener('show.bs.offcanvas', e => {
    if (e.target === mobileNavbar) headerElement.classList.add('showing');
  })

  mobileNavbar.addEventListener('shown.bs.offcanvas', e => {
    if (e.target === mobileNavbar) {
      headerElement.classList.remove('showing');
      headerElement.classList.add('show');
    }
  })

  window.addEventListener('resize', () => {
    const MEDIA_QUERY = window.matchMedia(navMaxWidthQuery)
    if (MEDIA_QUERY.matches) {
      Offcanvas.getOrCreateInstance(mobileNavbar).hide();
      mobileSubNavs.forEach(mobileSubNav => Offcanvas.getOrCreateInstance(mobileSubNav).hide());
    }
  })
}
