import {
  getInitialOffset,
  getInitialWidth, getRealOffset, getRelativeElementWidth, initIndicator,
  setElementOffset,
  setElementWidth
} from "../components/indicator/indicator.js";

export function initNavbarIndicator() {
  const navigationElement = document.querySelector('.navbar-desktop .navbar-nav.primary'),
    navbarRootElement = document.querySelector('header .navbar-root'),
    navbarBorderElement = document.querySelector('header .navbar-border'),
    activeMainNavLinkElement = document.querySelector('header .navbar-desktop .navbar-nav.primary > .nav-item > .nav-link.show, header .navbar-desktop .navbar-nav.primary > .nav-item > .nav-link.active'),
    megaNavs = document.querySelectorAll('header .navbar-mega-nav');

  if (navigationElement) {

    let initialWidth = getInitialWidth(activeMainNavLinkElement),
      initialOffset = getInitialOffset(activeMainNavLinkElement, navbarRootElement);

    initIndicator(activeMainNavLinkElement, navbarBorderElement, navbarRootElement);
    setTimeout(() => navbarBorderElement.style.removeProperty('transition'), 900);

    navigationElement.addEventListener('mouseover', event => {
      if (event.target.classList.contains('nav-link')) {
        const itemRealOffset = getRealOffset(event.target, navbarRootElement);
        setElementWidth(navbarBorderElement, getRelativeElementWidth(event.target));
        setElementOffset(navbarBorderElement, itemRealOffset);
        initialOffset = !activeMainNavLinkElement ? itemRealOffset : initialOffset;
      }
    });

    navigationElement.addEventListener('mouseleave', e => {
      const nextElementIsMegaMenu = e.relatedTarget ? e.relatedTarget.closest('.navbar-mega-nav') : false;
      if (!nextElementIsMegaMenu) {
        setElementWidth(navbarBorderElement, initialWidth);
        setElementOffset(navbarBorderElement, initialOffset);
      }
    });

    megaNavs.forEach(megaNavItem => megaNavItem.addEventListener('mouseleave', e => {
      const nextElementIsNavLink = e.relatedTarget ? e.relatedTarget.classList.contains('nav-link') : false;
      if (!nextElementIsNavLink) {
        setElementWidth(navbarBorderElement, initialWidth);
        setElementOffset(navbarBorderElement, initialOffset);
      }
    }))
  }
}
