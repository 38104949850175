export function getElementPaddingRight(element) {
  return element ? Number(getComputedStyle(element).getPropertyValue('padding-right').replace('px', '')) : 0
}

export function getRealOffset(element, rootElement, allowPadding = false) {
  const elementPaddingRight = getElementPaddingRight(element),
    positionCalculation = (element.getBoundingClientRect().x - rootElement.getBoundingClientRect().x);
  return !allowPadding ? positionCalculation + elementPaddingRight : positionCalculation;
}

export function getRelativeElementWidth(element, allowPadding = false) {
  const elementPaddingRight = getElementPaddingRight(element),
    elementRealWidth = element ? element.getBoundingClientRect().width : 0;
  return !allowPadding ? elementRealWidth - (elementPaddingRight * 2) : elementRealWidth;
}

export function getInitialWidth(activeElement, allowPadding = false) {
  return activeElement ? getRelativeElementWidth(activeElement, allowPadding) : 0;
}

export function getInitialOffset(activeElement, rootElement, allowPadding = false) {
  return activeElement ? getRealOffset(activeElement, rootElement, allowPadding) : 0;
}

export function setElementOffset(element, offset) {
  element.style.setProperty('--underline-offset-x', `${offset}px`);
}

export function setElementWidth(element, width) {
  element.style.setProperty('--underline-width', `${width}px`);
}

export function initIndicator(activeElement, borderElement, rootElement, allowPadding = false) {
  let initialWidth = getInitialWidth(activeElement, allowPadding),
    initialOffset = getInitialOffset(activeElement, rootElement, allowPadding)


  setElementWidth(borderElement, initialWidth);
  setElementOffset(borderElement, initialOffset);
  borderElement.style.setProperty('transition', `opacity 90ms ease-in`);
  borderElement.style.setProperty('--underline-opacity', `1`);
}
