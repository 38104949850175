import {
  activateMegaMenu,
  deactivateMegaMenu,
  mouseLeaveMegaMenu
} from "../components/navbar/navbar.js";

export function initNavbarMegaMenu() {
  const bodyElement = document.querySelector('body'),
    headerElement = document.querySelector('header') || false,
    navbarElement = document.querySelector('header .navbar') || false,
    mainNavElement = navbarElement ? navbarElement.querySelector('header .navbar-desktop .navbar-nav.primary') : false,
    megaMenuElement = document.querySelector('header .navbar-mega') || false,
    megaMenuNavigationElements = document.querySelectorAll('header .navbar-mega-nav') || false,
    mainNavItems = mainNavElement.querySelectorAll('.navbar-desktop .navbar-nav.primary .nav-link[data-target]') || false;

  let mouseEnterTimer;


  megaMenuNavigationElements.forEach(megaMenuNavigationElement => {
    megaMenuNavigationElement.addEventListener('mouseover', () => activateMegaMenu(headerElement, bodyElement, megaMenuElement));

    megaMenuNavigationElement.addEventListener('mouseleave', e => {
      if (mouseLeaveMegaMenu(e)) deactivateMegaMenu(headerElement, bodyElement, megaMenuElement, mainNavItems, megaMenuNavigationElements);
    });
  })

  mainNavItems.forEach(mainNavItem => {

    mainNavItem.addEventListener('mouseenter', e => {

      const mainNavItemTargetString = mainNavItem.getAttribute('data-target'),
        mainNavItemTargetElement = mainNavItemTargetString ? document.querySelector(`${mainNavItemTargetString}`) : false,
        innerContainerHeightElement = mainNavItemTargetElement ? document.querySelector(`${mainNavItemTargetString} > .row`) : false;

      if (mouseLeaveMegaMenu(e)) {
        mouseEnterTimer = setTimeout(() => {
          activateMegaMenu(headerElement, bodyElement, megaMenuElement);
        }, 200);
      }

      if (mainNavItemTargetElement) {

        megaMenuNavigationElements.forEach(NAV => NAV.classList.remove('show'));
        mainNavItemTargetElement.classList.add('show');

        megaMenuElement.style.setProperty('--container-height', `${innerContainerHeightElement ?
          Math.ceil(innerContainerHeightElement.getBoundingClientRect().height + 1) :
          Math.ceil(mainNavItemTargetElement.getBoundingClientRect().height + 1)}px`);

        mainNavItems.forEach(ITEM => ITEM.classList.remove('show'));
        mainNavItem.classList.add('show');
      }
    });

    mainNavItem.addEventListener('mouseleave', e => {
      if (mouseLeaveMegaMenu(e)) {
        clearTimeout(mouseEnterTimer);
        deactivateMegaMenu(headerElement, bodyElement, megaMenuElement, mainNavItems, megaMenuNavigationElements);
      }
    });
  })
}
