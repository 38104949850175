export function initNavbarHeightVariable() {
  const bodyElement = document.querySelector('body'),
    headerElement = document.querySelector('header');

  bodyElement.style.setProperty('--header-height', `${headerElement.getBoundingClientRect().height}px`);
  window.addEventListener('resize', () => {
    setTimeout(() => {
      bodyElement.style.setProperty('--header-height', `${headerElement.getBoundingClientRect().height}px`)
    }, 450)
  });
}
